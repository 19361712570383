import { hexToRGB } from '@utils/hexToRgba';
import theme from 'src/shared/styles/theme';
import { breakpoint, fonts } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray450, 0.7)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ModalBox = styled.div`
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    background: ${(props) => props.theme.colors.text.white};
    //width: 290px;
    height: auto;
    left: 479px;
    top: 162px;
    border-radius: 16px;
    padding: 20px;
    width: 90%;
    max-width: 900px;
    button:first-child {
        position: absolute;
        right: -35px;
        top: -15px;
        height: min-content;
        margin-right: 0;
        align-self: flex-end;
    }

    button:first-child span {
        color: black;
        font-size: 25px;
    }

    button:last-child {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    button.detail:first-child {
        position: unset;
        margin: auto;
    }

    ${breakpoint('md')`

        button:last-child {
            margin-top: 35px;
        }


    `}
`;

export const ModalWrapper = styled.div`
    position: relative;
    margin: 0 20px;
`;

export const ContentOrder = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
`;
export const ContentOrderItem = styled.div`
    color: #000;
`;
export const ContentOrderItemTitle = styled.div`
    color: #000;
    margin-bottom: 5px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 15px;
    line-height: 18px;
`;
export const ContentOrderItemDescription = styled.div`
    color: #000;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 16px;
`;


export const ContentDetail = styled.div`
    text-align: center;
    padding-top: 1rem
`;