import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export interface IHistory {
    token: string;
    tipoDocumento: string;
    usuario: string;
    limit: number;
    offset: number;
}

export const queriesHistory = {
    getHistory: gql`
        query ($input: historyInput!) {
            getHistory(input: $input) {
                id
                reference
                state
                total
                responseJson
                createdAt
                __typename
            }
        }
    `
};

export const historyService = {
    getHistory: (input: IHistory) => {
        return client.query({
            query: queriesHistory.getHistory,
            variables: {
                input
            }
        });
    }
};
