import React, { useContext } from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { WrapperShoppingHistory, ContainerTitle, Title } from './styled';

import Viewport from 'src/shared/hooks/viewport';
import { historyService } from '@services/history';
import TableShoppingHistory from '../../molecules/tableShoppingHistory/tableShoppingHistory';
import { graphql, useStaticQuery } from 'gatsby';
import { colorText } from '@utils/colorText';

const ShoppingHistory = () => {
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Historial de compras',
            pathname: 'historial-de-compras/'
        }
    ];


    const DataHistory = useStaticQuery(graphql`
        query DataHistory {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "HistorialDeCompras" } }) {
                nodes {
                    title
                    pageIcon {
                        description
                        file {
                            url
                        }
                        title
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ pageIcon, title, metaData }]
        }
    } = DataHistory;
    
    return (
        <Layout>
            <SEO
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
                slug="/Historial-de-compras/"
                index='noindex, nofollow'
            />
            <WrapperShoppingHistory>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
                <ContainerTitle>
                    <img src={pageIcon.file.url} alt={pageIcon.title} />
                    <Title  dangerouslySetInnerHTML={{ __html: colorText(title) }}/>
                </ContainerTitle>
                <TableShoppingHistory />
            </WrapperShoppingHistory>
        </Layout>
    );
};

export default ShoppingHistory;
