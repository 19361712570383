import React, { useContext, useEffect, useState } from 'react';
import {
    ContainerListHistory,
    CardHistory,
    CardHistoryHeader,
    CardHistoryBody,
    CardItem,
    ContainerTableHistory,
    HeaderTable,
    ContentTable,
    ContainerContentTable,
    RowTable,
    StateOperation,
    ContainerLoadMore
} from './styled';
import { AppContextUser } from '../../../shared/context/user';
import { historyService } from '@services/history';
import { Button } from '@componentsShared';
import ModalOrder from '../modalOrder';
import Viewport from '../../../shared/hooks/viewport';
import { converterNumber } from '@utils/decimalNumberConverter';
import { getData } from '@utils/transferData';
import siteUrl from '@utils/siteUrl';
import { pushEskEvent, pushFbqEvent, pushGtagEvent } from '@utils/metricManagementUtil';

const TableShoppingHistory = () => {
    const { dataUser } = useContext(AppContextUser);
    const [dataHistory, setDataHistory] = useState<any>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [infoModal, setInfoModal] = useState();
    const viewport = Viewport();
    const [isPaymentGateway, setTsPaymentGateway] = useState<boolean>(false);

    const hasOrder = (response: string, status: string) => {
        const data = JSON.parse(response);
        return data && status.toUpperCase() != 'PENDING' && (data.requestId || data.CODE);
    }

    useEffect(() => {
        let userData = getData('userData');

        if (dataUser.isLogged) {
            getHistory();
        } else if (!userData?.isLogged) {
            window.location.href = siteUrl + '/';
        }
        let isConfirmPurchase = window.location?.hash.includes('confirmacion-compra')
        setTsPaymentGateway(isConfirmPurchase);
    }, [dataUser]);

    const getHistory = async () => {
        await historyService
            .getHistory({
                token: dataUser.token,
                tipoDocumento: dataUser.tipoDocumento,
                usuario: dataUser.usuario,
                limit: 1,
                offset: 18
            })
            .then((data) => {
                setDataHistory(data.data.getHistory);
                if(isPaymentGateway){
                    pushGtagEvent('purchase', {
                        transaction_id: data?.data?.getHistory[0]?.reference || '',
                        value: data?.data?.getHistory[0]?.total || 0,
                        tax: '',
                        currency: 'COP',
                        coupon: ''
                    });
                    pushFbqEvent('Purchase', {
                        currency: 'COP',
                        value: data?.data?.getHistory[0]?.total || 0,
                    });
                    pushEskEvent();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openModal = (index: number) => {
        setInfoModal(dataHistory[index]);
        setIsOpenModal(true);
    };

    const closeModal = () => {
        setIsOpenModal(false);
    };

    const itemsView: number = 10;
    const handleLoadMore = () => {
        if (page < dataHistory.length / itemsView) {
            setPage(page + 1);
        }
    };

    const getState: any = {
        REJECTED: {
            name: 'RECHAZADA',
            color: 'red'
        },
        APPROVED: {
            name: 'APROBADA',
            color: 'green'
        },
        PENDING: {
            name: 'PENDIENTE',
            color: 'orange'
        }
    };

    return (
        <>
            {viewport.mobile && (
                <>
                    <ContainerListHistory>
                        {dataHistory.map((item: any, index: number) => {
                            if (item.createdAt !== null) {
                                const isOrderView = hasOrder(item.responseJson, item.state);
                                if (index < page * itemsView) {
                                    return (
                                        <>
                                            <CardHistory key={`card-${index}`}>
                                                <CardHistoryHeader>
                                                    <CardItem>
                                                        <div>Fecha</div>
                                                        <div>{item.createdAt}</div>
                                                    </CardItem>
                                                    <CardItem>
                                                        <div>Referencia</div>
                                                        <div>{item.reference}</div>
                                                    </CardItem>
                                                    <CardItem>
                                                        <div>Estado</div>
                                                        <StateOperation
                                                            color={getState[item.state].color}
                                                        >
                                                            {getState[item.state].name}
                                                        </StateOperation>
                                                    </CardItem>
                                                    <CardItem>
                                                        <div>Valor</div>
                                                        <div>$ {converterNumber(item.total)}</div>
                                                    </CardItem>
                                                </CardHistoryHeader>
                                                <CardHistoryBody>
                                                    <div>Acciones</div>
                                                    <div>
                                                        <Button
                                                            disabled={!isOrderView}
                                                            text="Ver orden"
                                                            onClick={() =>
                                                                isOrderView && openModal(index)
                                                            }
                                                        ></Button>
                                                    </div>
                                                </CardHistoryBody>
                                            </CardHistory>
                                        </>
                                    );
                                }
                            }
                        })}

                        {itemsView * page < dataHistory.length && (
                            <ContainerLoadMore>
                                <Button text="Cargar más" onClick={() => handleLoadMore()}></Button>
                            </ContainerLoadMore>
                        )}
                    </ContainerListHistory>
                </>
            )}

            {!viewport.mobile && (
                <>
                    <ContainerTableHistory>
                        <HeaderTable>
                            <h4>Fecha</h4>
                            <h4>Referencia</h4>
                            <h4>Estado</h4>
                            <h4>Valor</h4>
                            <h4>Acciones</h4>
                        </HeaderTable>
                        <ContainerContentTable>
                            <ContentTable activeScroll={true}>
                                {dataHistory.map((item: any, index: number) => {
                                    if (item.createdAt !== null) {
                                        const theme = index % 2 === 1 ? 'light' : 'dark';
                                        const isOrderView = hasOrder(item.responseJson, item.state);
                                        if (index < page * itemsView) {
                                            return (
                                                    <RowTable
                                                        key={'RowTable' + index}
                                                        className={theme}
                                                    >
                                                        <div>{item.createdAt}</div>
                                                        <div>{item.reference}</div>
                                                        <div>
                                                            <StateOperation
                                                                color={getState[item.state].color}
                                                            >
                                                                {getState[item.state].name}
                                                            </StateOperation>
                                                        </div>
                                                        <div>$ {converterNumber(item.total)}</div>
                                                        <div>
                                                            <Button
                                                                disabled={!isOrderView}
                                                                text="Ver orden"
                                                                onClick={() =>
                                                                    isOrderView && openModal(index)
                                                                }
                                                            ></Button>
                                                        </div>
                                                    </RowTable>
                                            );
                                        }
                                    }
                                })}
                                {itemsView * page < dataHistory.length && (
                                    <ContainerLoadMore>
                                        <Button
                                            text="Cargar más"
                                            onClick={() => handleLoadMore()}
                                        ></Button>
                                    </ContainerLoadMore>
                                )}
                            </ContentTable>
                        </ContainerContentTable>
                    </ContainerTableHistory>
                </>
            )}

            {isOpenModal && <ModalOrder closeModal={closeModal} order={infoModal} />}
        </>
    );
};

export default TableShoppingHistory;
