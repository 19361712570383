import React, { useEffect } from 'react';
import {
    ModalContainer,
    ModalBox,
    ModalWrapper,
    ContentOrder,
    ContentOrderItem,
    ContentOrderItemTitle,
    ContentOrderItemDescription,
    ContentDetail
} from './styled';
import { Button, ButtonIcon } from '@componentsShared';
import moment from 'moment';
import 'moment/locale/es';
import { converterNumber } from '@utils/decimalNumberConverter';
import { getDataModalOder, getDataOrder } from '@utils/history';
import { setData } from '@utils/transferData';
import siteUrl from '@utils/siteUrl';

interface IProps {
    closeModal: () => void;
    order?: any;
}

const ModalOrder = ({ closeModal, order }: IProps) => {
    moment.locale('es');
    const info = JSON.parse(order.responseJson);
    const data = getDataModalOder(info, order);
    let date = moment(data.date).format('dddd, D [de] MMMM [del] YYYY h:mm a');


    const getState: any = {
        REJECTED: {
            name: 'RECHAZADA',
            color: 'red'
        },
        APPROVED: {
            name: 'APROBADA',
            color: 'green'
        },
        PENDING: {
            name: 'PENDIENTE',
            color: 'orange'
        }
    };

    const showDetail = (data: any) => {
        const orderData = getDataOrder(data, order.id, order.reference, order.createdAt);
        setData(orderData, "orderData");
        window.location.href = siteUrl + '/historial-de-compras/colillas/';
    }

    return (
        <ModalContainer>
            <ModalBox>
                <ModalWrapper>
                    <ButtonIcon
                        icon="cross-small"
                        onClick={() => closeModal()}
                        title="Cerrar"
                    ></ButtonIcon>
                    <ContentOrder>
                        <ContentOrderItem>
                            <ContentOrderItemTitle>Nombre del comprador</ContentOrderItemTitle>
                            <ContentOrderItemDescription>
                                {data.name}
                            </ContentOrderItemDescription>
                        </ContentOrderItem>

                        <ContentOrderItem>
                            <ContentOrderItemTitle>
                                Correo electrónico del comprador
                            </ContentOrderItemTitle>
                            <ContentOrderItemDescription>
                                {data.email}
                            </ContentOrderItemDescription>
                        </ContentOrderItem>

                        <ContentOrderItem>
                            <ContentOrderItemTitle>Fecha de transacción</ContentOrderItemTitle>
                            <ContentOrderItemDescription>{date}</ContentOrderItemDescription>
                        </ContentOrderItem>

                        <ContentOrderItem>
                            <ContentOrderItemTitle>Estado de aprobación</ContentOrderItemTitle>
                            <ContentOrderItemDescription>{getState[data.status].name}</ContentOrderItemDescription>
                        </ContentOrderItem>

                        {data.status === 'APPROVED' && (
                            <>
                            <ContentOrderItem>
                                <ContentOrderItemTitle>Franquicia</ContentOrderItemTitle>
                                <ContentOrderItemDescription>{data.franchise} </ContentOrderItemDescription>
                            </ContentOrderItem>

                            <ContentOrderItem>
                            <ContentOrderItemTitle>Total a pagar</ContentOrderItemTitle>
                            <ContentOrderItemDescription>$ {converterNumber(data.total)} </ContentOrderItemDescription>
                            </ContentOrderItem>

                            <ContentOrderItem>
                            <ContentOrderItemTitle>Banco</ContentOrderItemTitle>
                            <ContentOrderItemDescription> </ContentOrderItemDescription>
                            </ContentOrderItem>

                            <ContentOrderItem>
                            <ContentOrderItemTitle>Referencia</ContentOrderItemTitle>
                            <ContentOrderItemDescription>{data.reference}</ContentOrderItemDescription>
                            </ContentOrderItem>

                            <ContentOrderItem>
                            <ContentOrderItemTitle>Aut/CUS</ContentOrderItemTitle>
                            <ContentOrderItemDescription>{data.cus}</ContentOrderItemDescription>
                            </ContentOrderItem>
                            </>

                        )}
                    </ContentOrder>
                    {data.status === 'APPROVED' && (
                    <ContentDetail>
                        <Button
                            text="Ver colillas"
                            onClick={() => { showDetail(info) }}
                            typeButton="detail"
                        ></Button>
                    </ContentDetail>
                    )}
                </ModalWrapper>
            </ModalBox>
        </ModalContainer>
    );
};

export default ModalOrder;
