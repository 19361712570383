import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import { TPropsUI } from '../../../shared/components/molecules/gameTableThreeColumns/types';
import { IPropsState } from './types';

export const ContainerListHistory = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 30px auto;
    background: #1d1828;
    padding: 16px;
    border-radius: 20px;
`;

export const CardHistory = styled.div`
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.colors.backgrounds.gray400};
`;

export const CardHistoryHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
`;

export const CardItem = styled.div`
    div:first-child {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        margin-bottom: 6px;
        font-size: 15px;
    }
    div:last-child {
        font-family: ${(props) => props.theme.fonts.mon_regular};
        font-size: 13px;
    }
`;

export const CardHistoryBody = styled.div`
    margin-top: 12px;
    div:first-child {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        margin-bottom: 6px;
        font-size: 15px;
        text-align: center;
    }
    div:last-child {
        display: flex;
        justify-content: center;
    }

    button {
        padding: 5px 15px;
        font-size: 13px;
    }
`;

export const ContainerTableHistory = styled.div`
    max-width: 950px;
    max-height: 588px;
    margin: 60px auto;
    background-color: ${(props) => props.theme.colors.backgrounds.gray450};
    position: relative;
    border-radius: 16px;

    ${breakpoint('md')`
      max-height: 456px;
    `};
`;

export const HeaderTable = styled.div`
    width: 100%;
    height: 69px;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
    display: flex;
    align-items: center;
    padding: 0px 18px;

    h4 {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 16px;
        margin-top: 15px;
        width: 33%;
        text-align: center;
    }
`;

export const ContainerContentTable = styled.div`
    width: 100%;
    max-height: 519px;
    margin-top: 7px;

    ${breakpoint('md')`
        max-height: 373px;
    `};
`;
export const ContentTable = styled.div<TPropsUI>`
    width: 100%;
    max-height: 519px;
    margin: 0 auto;
    overflow-y: ${(props) => (props.activeScroll ? `scroll` : `hidden`)};

    ${breakpoint('md')`
      max-height: 373px;
      padding: 0px 9px;


        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        ::-webkit-scrollbar-thumb {
            background: #1d1828;
        }
        ::-webkit-scrollbar:vertical {
            width: 1px;
        }
        ::-webkit-scrollbar-track {
            background: #c5c3c9;
        }
    `};
`;

export const RowTable = styled.div`
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 40px;
    margin: 4px 0;
    font-family: ${(props) => props.theme.fonts.mon_regular};

    div {
        width: 33%;
        text-align: center;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 10px;
    }

    &.light {
        background-color: ${(props) => props.theme.colors.backgrounds.gray400};
    }

    &.dark {
        background-color: ${(props) => props.theme.colors.backgrounds.gray450};
    }
`;

export const StateOperation = styled.span<IPropsState>`
    width: fit-content;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 16px;
    background: ${(props) => props.theme.colors.alerts.red};
    font-family: ${(props) => props.theme.fonts.mon_regular};

    background: ${(props) =>
        props.color === 'green'
            ? props.theme.colors.alerts.green
            : props.color === 'red'
            ? props.theme.colors.alerts.red
            : props.theme.colors.alerts.orange};

    ${breakpoint('md')`
          padding: 6px 10px;

    `};
`;

export const ContainerLoadMore = styled.div`
    margin: 15px 0;
    display: flex;
    justify-content: center;
`;
